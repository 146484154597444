import { atom } from "recoil";

export const portfolioScannerAtom = atom({
  key: "portfolioScannerAtom",
  default: {
    initialized: false,
    isConfigOpened: false,
    displayHiddenPosts: true,
    companyIds: [] as number[],
  },
});

import * as signalR from "@microsoft/signalr";
import { useRecoilState } from "recoil";
import { autoRefreshActiveAtom } from "../atoms/autoRefreshActiveAtom";
import { useEffect } from "react";

export const usePostsNotifier = function () {
  const [_, setAutoRefeshActive] = useRecoilState(autoRefreshActiveAtom);

  let connection: signalR.HubConnection | null = null;

  const url = process.env.REACT_APP_SIGNALR_HUB_URL;

  useEffect(() => {
    const startSignalRConnection = async () => {
      connection = new signalR.HubConnectionBuilder()
        .withUrl(url)
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

      try {
        await connection.start();
        setAutoRefeshActive(true);
      } catch (err) {
        setTimeout(startSignalRConnection, 5000);
        setAutoRefeshActive(false);
      }

      connection.onclose(async () => {
        setAutoRefeshActive(false);
      });

      connection.onreconnecting(async () => {
        setAutoRefeshActive(false);
      });

      connection.onreconnected(() => {
        setAutoRefeshActive(true);
      });
    };

    (async () => {
      await startSignalRConnection();
    })();
  }, []);

  const onNewPost = (callback: (arg0: string) => void) => {
    if (connection) {
      connection.on("ReceiveNewPost", callback);
    } else {
      console.error("SignalR connection not established.");
    }
  };

  const stopWaitingForPosts = async () => {
    if (connection) {
      await connection.stop();
    }
  };

  return { onNewPost, stopWaitingForPosts };
};

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "../../../components/Tooltip";
import { useRecoilState } from "recoil";
import { autoRefreshActiveAtom } from "../../../atoms/autoRefreshActiveAtom";

export const AutoRefreshIcon = () => {
  const [active] = useRecoilState(autoRefreshActiveAtom);
  return (
    <span className="relative mr-3 inline-block">
      <Tooltip
        className="top-6"
        message={
          <>
            Automatyczne
            <br /> odświeżanie
            <br /> {active ? "aktywne" : "nieaktywne"}
          </>
        }
      >
        <ArrowPathIcon aria-hidden="true" className="h-5 w-5" />
        <span
          className={`absolute right-0 top-0 block h-2 w-2 -translate-y-1/2 translate-x-1/2 transform rounded-full ${active ? "bg-green-400" : "bg-red-400"}`}
        />
      </Tooltip>
    </span>
  );
};

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "../../../components/Tooltip";

interface RefreshIconProps {
  onClick: () => void
}

export const RefreshIcon = (props: RefreshIconProps) => {
  return (
    <Tooltip
      className="top-6"
      message="Odśwież zakładki"
    >
      <span className="cursor-pointer relative mr-3 inline-block" onClick={() => props.onClick()}>
        <ArrowPathIcon aria-hidden="true" className="h-5 w-5" />
      </span>
    </Tooltip>
  );
};

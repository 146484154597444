import { atom } from "recoil";
import { Company } from "../entities/company";

export const portfolioScannerConfigAtom = atom({
  key: "portfolioScannerConfigAtom",
  default: {
    isLoading: false,
    query: "",
    selectedCompanies: [] as Company[],
    availableCompanies: [] as Company[],
    displayHiddenPosts: true,
  },
});

interface BiznesRadarLinkProps {
    ticker: string;
}

export const BiznesRadarLink = (props: BiznesRadarLinkProps) => {
    return (<a
        target="_blank"
        href={`https://www.biznesradar.pl/notowania/${props.ticker}`}
        className="text-sm"
        rel="nofollow noreferrer"
    >
        <div className="mt-4 inline-flex justify-center gap-1.5 align-middle">
            <div>
                <img
                    src="/icons/biznesradar.png"
                    title="BiznesRadar"
                    className="mt-0.5 h-4 w-4"
                />
            </div>
            <div>BiznesRadar</div>
        </div>
    </a>);
};
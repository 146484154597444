import { Post } from "../entities/post";
import { HiddenPostType } from "../enums/hidden-post-type";
import {
  BookmarkIcon,
  ArchiveBoxXMarkIcon,
  ArchiveBoxIcon,
} from "@heroicons/react/24/outline";
import { BookmarkIcon as SolidBookmarkIcon } from "@heroicons/react/20/solid";
import { intersperse } from "../helpers/intersperse";
import { Link } from "react-router-dom";

interface CompanyPostProps {
  post: Post;
  showCompanyNames: boolean;
  bookmark: (set: boolean) => void;
  hide: (set: boolean) => void;
}

export const CompanyPost = (props: CompanyPostProps) => {
  const { post, showCompanyNames } = props;

  const muted = (post: Post) => post.hidden !== HiddenPostType.Visible;
  const getPublishTime = (post: Post) => {
    const date = new Date(post.published);
    const minutes = date.getMinutes();
    return `${date.getHours()}:${minutes <= 9 ? `0${minutes}` : minutes}`;
  };

  return (
    <li
      key={post.id}
      className={`flex gap-x-6 py-5 ${muted(post) ? "opacity-55" : ""}`}
    >
      <div className="min-w-12">
        <Link to={`/s/${post.sourceSimplifiedName}`}>
          <img
            alt={post.sourceIcon}
            src={`/icons/${post.sourceIcon}`}
            className="h-12 w-12 flex-none rounded-full bg-gray-50"
          />
        </Link>
      </div>
      <div className="flex-grow">
        <Link
          to={post.url}
          target="_blank"
          rel="noreferrer"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          {post.title}
        </Link>
        <div className="my-2 text-xs leading-5 text-gray-500">
          <p>
            {post.description?.length > 404
              ? `${post.description.substring(0, 404)} ...`
              : post.description}
          </p>
        </div>
        <div className="text-black-500 mt-1 flex items-center gap-x-2 text-xs leading-5">
          <p className="whitespace-nowrap">{getPublishTime(post)}</p>
          {showCompanyNames && (
            <>
              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                <circle r={1} cx={1} cy={1} />
              </svg>
              <p className="truncate">
                {intersperse(
                  post.companies.map((company) => (
                    <Link key={company.ticker} to={`/c/${company.ticker}`}>
                      {company.name}
                    </Link>
                  )),
                  <>, </>,
                )}
              </p>
            </>
          )}
        </div>
      </div>
      <div className="flex items-center">
        {/* <div className="flex min-w-[20px] flex-col gap-5 md:min-w-[60px] md:flex-row"> */}
        {post.hidden === HiddenPostType.Visible &&
          (post.bookmarked ? (
            <SolidBookmarkIcon
              onClick={() => props.bookmark(false)}
              title="test"
              className="h-5 w-5 cursor-pointer text-green-500"
            />
          ) : (
            <BookmarkIcon
              onClick={() => props.bookmark(true)}
              title="test"
              className="h-5 w-5 cursor-pointer text-gray-400 hover:text-green-500"
            />
          ))}
        {/* {post.hidden !== HiddenPostType.Visible ? (
            <ArchiveBoxIcon
              onClick={() => props.hide(false)}
              aria-hidden="true"
              className="h-5 w-5 cursor-pointer text-gray-400 hover:text-green-500"
            />
          ) : (
            <ArchiveBoxXMarkIcon
              onClick={() => props.hide(true)}
              aria-hidden="true"
              className="h-5 w-5 cursor-pointer text-gray-400 hover:text-red-500"
            />
          )} */}
        {/* </div> */}
      </div>
    </li>
  );
};

import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  DialogBackdrop,
} from "@headlessui/react";
import { Company } from "../../../entities/company";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { Loader } from "../../../components/Loader";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { api, cancelApiObject } from "../../../api";
import { useRecoilState } from "recoil";
import { displayCompanySearchAtom } from "../../../atoms/displayCompanySearchAtom";

class CompanySearchState {
  isLoading = false;
  query = "";
  companies = [] as Company[];
}

export const CompanySearch = () => {
  const [displayCompanySearch, setDisplayCompanySearch] = useRecoilState(displayCompanySearchAtom);
  const [state, setState] = useState(new CompanySearchState());
  const [debouncedQuery] = useDebounce(state.query, 100);
  const navigate = useNavigate();

  const gotoCompany = (company: Company) => {
    if (company) {
      navigate(`c/${company.ticker.toLocaleLowerCase()}`);
      setState({
        ...state,
        query: "",
        companies: [],
      });
      setDisplayCompanySearch(false);
    }
  };

  const close = () => {
    setState({
      ...state,
      isLoading: false,
      query: "",
      companies: [],
    });
    setDisplayCompanySearch(false);
  };

  const clear = () => {
    setState({
      ...state,
      isLoading: false,
      query: "",
      companies: [],
    });
  };

  const startSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      isLoading: true,
      query: event.target.value,
    });
  };

  useEffect(() => {
    if (state.query.length <= 1) {
      clear();
      return;
    }

    (async () => {
      try {
        const companies = await api.searchCompanies(debouncedQuery);
        setState({ ...state, companies, isLoading: false });
      } catch {
        setState({ ...state, isLoading: false });
      }
    })();

    return () => {
      cancelApiObject[state.query]?.handleRequestCancellation();
    };
  }, [debouncedQuery]);

  return (
    <Dialog
      className="relative z-10"
      open={displayCompanySearch}
      onClose={close}
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
        <DialogPanel
          transition
          className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <Combobox onChange={gotoCompany}>
            <div className="relative">
              <MagnifyingGlassIcon
                className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <ComboboxInput
                autoFocus
                className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 outline-none placeholder:text-gray-400 sm:text-sm"
                placeholder="Wpisz nazwę spółki aby wyszukać"
                onChange={startSearch}
                onBlur={close}
              />
              {state.isLoading && (
                <div className="absolute right-4 top-3" role="status">
                  <Loader className="h-6 w-6" />
                </div>
              )}
            </div>

            {state.companies.length > 0 && (
              <ComboboxOptions
                static
                className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
              >
                {state.companies.map((company) => (
                  <ComboboxOption
                    key={company.id}
                    value={company}
                    className="cursor-default select-none px-4 py-2 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                  >
                    {company.name}
                  </ComboboxOption>
                ))}
              </ComboboxOptions>
            )}

            {state.companies.length === 0 && state.query.length > 2 && (
              <p className="p-4 text-sm text-gray-500">Nie znaleziono spółki</p>
            )}
          </Combobox>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

interface BankierLinkProps {
    abberviation: string;
}

export const BankierLink = (props: BankierLinkProps) => {
    return (<a
        target="_blank"
        href={`https://www.bankier.pl/inwestowanie/profile/quote.html?symbol=${props.abberviation}`}
        className="text-sm"
        rel="nofollow noreferrer"
    >
        <div className="mr-4 mt-4 inline-flex justify-center gap-1.5 align-middle">
            <div>
                <img
                    src="/icons/bankier.png"
                    title="Bankier"
                    className="mt-0.5 h-4 w-4"
                />
            </div>
            <div>Bankier</div>
        </div>
    </a>);
};
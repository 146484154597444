import { ReactNode } from "react";

interface TooltipProps {
  message: string | ReactNode;
  children: ReactNode;
  className?: string;
}

export const Tooltip = (props: TooltipProps) => {
  return (
    <div className="group relative flex max-w-max flex-col items-center justify-center">
      {props.children}
      <div
        className={`${props.className} absolute left-1/2 ml-auto mr-auto min-w-max -translate-x-1/2 scale-0 transform rounded-lg px-3 py-2 text-xs font-medium transition-all duration-500 group-hover:scale-100`}
      >
        <div className="flex max-w-xs flex-col items-center shadow-lg">
          <div className="clip-bottom h-3 w-6 bg-zinc-600"></div>
          <div className="-mt-1 rounded bg-gray-600 p-2 text-center text-xs text-white">
            {props.message}
          </div>
        </div>
      </div>
    </div>
  );
};
